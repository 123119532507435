import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000, // Cache selama 5 menit
      cacheTime: 10 * 60 * 1000, // Waktu cache tersimpan sebelum dihapus
      refetchOnWindowFocus: false, // Tidak fetch ulang saat fokus kembali ke tab
    },
  },
});

export default queryClient;
