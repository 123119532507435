import React from 'react';

function ProductDetailSkeleton() {
  return (
    <div className="min-h-screen py-10 px-4 bg-gray-800 text-white">
      <div className="w-full h-56 bg-gray-700 animate-pulse rounded-t-lg mb-4"></div>
      <div className="flex items-center gap-4 mb-4">
        <div className="w-20 h-20 bg-gray-700 rounded-full animate-pulse"></div>
        <div className="flex flex-col gap-2">
          <div className="w-32 h-6 bg-gray-700 animate-pulse"></div>
          <div className="w-48 h-4 bg-gray-600 animate-pulse"></div>
        </div>
      </div>
      <div className="w-full h-12 bg-gray-700 animate-pulse rounded-md mb-6"></div>
      <div className="mt-8 flex items-center gap-2">
        <div className="w-24 h-6 bg-gray-700 animate-pulse rounded-md"></div>
      </div>
      <div className="overflow-x-auto mt-6">
        <div className="flex gap-4">
          <div className="w-48 h-48 bg-gray-700 animate-pulse rounded-lg"></div>
          <div className="w-48 h-48 bg-gray-700 animate-pulse rounded-lg"></div>
          <div className="w-48 h-48 bg-gray-700 animate-pulse rounded-lg"></div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetailSkeleton;
