import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Using useNavigate for navigation

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login'); // Redirect to login if no token is found
      return;
    }

    const fetchUserData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/auth/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(response.data); // Set user data jika permintaan berhasil
      } catch (error) {
        console.error('Error fetching user data:', error); // Log error untuk debugging
    
        // Cek apakah ada pesan error dari server
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An unexpected error occurred.';
    
        setError(errorMessage); // Tampilkan pesan error ke layar
    
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token'); // Hapus token jika sudah tidak valid
          navigate('/login'); // Redirect ke halaman login
        }
      }
    };

    fetchUserData(); // Call fetchUserData when the component is mounted

  }, [navigate]); // Only rerun when navigate changes

if (error) {
  return (
    <div>
      <h2>Error</h2>
      <p>{error}</p> {/* Tampilkan pesan error */}
    </div>
  );
}


  return (
    <div>
      <h2>Welcome to the Dashboard</h2>
      {userData ? <p>Welcome, {userData.username}</p> : <p>Loading...</p>} 
    </div>
  );
};

export default Dashboard;
