import React, { useState } from "react";
import { LuCloudDownload, LuListMinus } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/slider.css";

const fetchProducts = async () => {
  const { data } = await axios.get("https://foxstore.web.id/");
  return data;
};

const fetchSliderData = async () => {
  const { data } = await axios.get("https://foxstore.web.id/web");
  return data[0]?.foto_slider || [];
};

function ProductList() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const { data: products, isLoading: loadingProducts } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
  });

  const { data: sliderData, isLoading: loadingSlider } = useQuery({
    queryKey: ["sliderData"],
    queryFn: fetchSliderData,
  });

  const categories = products ? [...new Set(products.map((item) => item.kategori))] : [];
  const filteredProducts =
    selectedCategory === "all"
      ? products
      : products.filter((product) => product.kategori === selectedCategory);

  const isLoading = loadingProducts || loadingSlider;

  const onCategoryClick = (category) => {
    setIsCategoryLoading(true);
    setTimeout(() => {
      setSelectedCategory(category);
      setIsCategoryLoading(false);
    }, 500); // Simulasi waktu loading
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="bg-slate-900 text-white min-h-screen">
      <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {isLoading ? (
          <>
            <div className="mb-10">
              <div className="h-64 md:h-80 lg:h-96 bg-gray-700 rounded-xl animate-pulse"></div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:gap-6">
              {Array.from({ length: 8 }).map((_, index) => (
                <div
                  key={index}
                  className="animate-pulse bg-slate-800 rounded-lg overflow-hidden"
                >
                  <div className="h-40 sm:h-48 bg-gray-700"></div>
                  <div className="p-4">
                    <div className="h-4 bg-gray-700 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-gray-700 rounded w-1/2"></div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {sliderData && sliderData.length > 0 ? (
              <Slider {...sliderSettings} className="mb-10">
                {sliderData.map((foto, index) => (
                  <div key={index} className="relative">
                    <img
                      src={foto}
                      alt={`Slide ${index + 1}`}
                      className="w-full h-64 md:h-80 lg:h-96 object-cover rounded-xl"
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="mb-10 text-center text-gray-400">
                <p>Tidak ada data slider untuk ditampilkan.</p>
              </div>
            )}

            <div
              className={`flex gap-6 mb-8 ${
                categories.length <= 3
                  ? "justify-center"
                  : "overflow-x-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900"
              }`}
            >
              <button
                onClick={() => onCategoryClick("all")}
                className={`flex-shrink-0 px-4 py-2 rounded-lg transition-all duration-300 ${
                  selectedCategory === "all"
                    ? "bg-blue-600 text-white shadow-md"
                    : "bg-gray-700 text-gray-300 hover:bg-blue-600 hover:text-white"
                }`}
              >
                <LuListMinus className="inline-block mr-2" /> Semua Kategori
              </button>
              {categories.map((category, index) => (
                <button
                  key={index}
                  onClick={() => onCategoryClick(category)}
                  className={`flex-shrink-0 px-4 py-2 rounded-lg transition-all duration-300 ${
                    selectedCategory === category
                      ? "bg-blue-600 text-white shadow-md"
                      : "bg-gray-700 text-gray-300 hover:bg-blue-600 hover:text-white"
                  }`}
                >
                  <LuListMinus className="inline-block mr-2" /> {category}
                </button>
              ))}
            </div>

            {isCategoryLoading ? (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 sm:gap-6">
                {Array.from({ length: 8 }).map((_, index) => (
                  <div
                    key={index}
                    className="animate-pulse bg-slate-800 rounded-lg overflow-hidden"
                  >
                    <div className="h-40 sm:h-48 bg-gray-700"></div>
                    <div className="p-4">
                      <div className="h-4 bg-gray-700 rounded w-3/4 mb-2"></div>
                      <div className="h-3 bg-gray-700 rounded w-1/2"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
                {filteredProducts.map((product) => (
                  <div key={product.id} className="bg-slate-800 rounded-lg overflow-hidden relative">
                    <Link to={`/detail/${product.id}`}>
                      <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-purple-700/10 ring-inset absolute top-2 right-2">
                        free
                      </span>
                      <img
                        src={product.foto}
                        alt={product.nama}
                        className="h-40 sm:h-48 w-full object-cover"
                      />
                      <div className="p-4">
                        <h3 className="text-lg font-semibold text-yellow-400">
                          {product.nama}
                        </h3>
                        <p className="text-sm text-gray-300">{product.kategori}</p>
                        <div className="flex justify-between items-center mt-2">
                          <p className="text-sm text-gray-400">
                            {product.download_count} Downloads
                          </p>
                          <LuCloudDownload className="text-yellow-400 text-lg" />
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductList;
