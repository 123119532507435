import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { LuAirplay, LuAppWindow, LuCloudDownload, LuStar } from "react-icons/lu";
import { useParams } from "react-router-dom";
import ProductDetailSkeleton from "../components/ProductDetailSkeleton";
import ProductBanner from "../components/ProductBanner";
import ProductInfo from "../components/ProductInfo";
import ProductDescription from "../components/ProductDescription";
import Rating from "../components/Rating";
import ProductSlider from "../components/ProductSlider";
import ImageModal from "../components/ImageModal";

// Fetch product details by ID
const fetchProductDetail = async (id) => {
  const { data } = await axios.get(`https://foxstore.web.id/detail/${id}`);
  return data;
};

// Update download count for the product
const updateDownloadCount = async (id) => {
  const { data } = await axios.post(`https://foxstore.web.id/update-downloads/${id}`, { download: 1 });
  return data;
};

function ProductDetail() {
  const { id } = useParams(); // Extract product ID from URL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isDownloading, setIsDownloading] = useState(false); // Prevent multiple downloads

  // Fetch product details using react-query
  const {
    data: product,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["product", id],
    queryFn: () => fetchProductDetail(id),
  });

  // Handle download action
  const handleDownload = () => {
    if (!product || isDownloading) return;

    setIsDownloading(true); // Prevent multiple clicks
    updateDownloadCount(id)
      .then(() => {
        console.log("Download count updated");
        window.location.href = product.link_download; // Redirect ke link download
      })
      .catch((error) => {
        console.error("Error updating download count:", error);
        alert("Failed to download the file. Please try again.");
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  // Open modal with selected image
  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  // Render loading state
  if (isLoading) return <ProductDetailSkeleton />;

  // Render error or product not found state
  if (isError || !product) {
    return (
      <div className="text-center py-10 text-white">
        <p>Error: Unable to load product details. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-10 px-4 bg-slate-900 text-white">
      {/* Product Banner */}
      <ProductBanner banner={product.banner} />

      {/* Product Info */}
      <ProductInfo product={product} handleDownload={handleDownload} />

      {/* Product Description */}
      <ProductDescription description={product.deskripsi} />

      {/* Product Rating */}
      <Rating rating={product.rating} />

      {/* Product Image Slider */}
      <ProductSlider photos={product.foto_slider} openModal={openModal} />

      {/* Image Modal */}
      <ImageModal isOpen={isModalOpen} image={selectedImage} onClose={closeModal} />

      {/* Attachments Section */}
      <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium text-white">Detail Script</dt>
        <dd className="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
          <ul className="divide-y divide-gray-700 rounded-lg border border-gray-700">
            {product.attachments?.length > 0 ? (
              product.attachments.map((attachment) => (
                <li
                  key={attachment.id}
                  className="flex items-center justify-between py-4 pr-5 pl-4"
                >
                  <div className="flex w-0 flex-1 items-center">
                    <svg
                      className="h-5 w-5 shrink-0 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">{attachment.name}</span>
                      <span className="shrink-0 text-gray-400">{attachment.size}</span>
                    </div>
                  </div>
                  <div className="ml-4 shrink-0 text-lg">
                    <button
                      onClick={handleDownload}
                      className="font-medium text-yellow-300 hover:text-indigo-500"
                      disabled={isDownloading} // Disable button while downloading
                    >
                      <LuCloudDownload />
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <p className="text-gray-400">No attachments available</p>
            )}
          </ul>
        </dd>
      </div>

      {/* Interactive Cards */}
      <div className="w-full overflow-x-auto py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
          <div className="flex gap-3 group rounded-lg border border-gray-700 p-5">
            <p className="text-white flex items-center text-lg font-medium">
              <LuCloudDownload className="text-yellow-400 mr-2" />
              {product.download_count} downloads
            </p>
          </div>
          <div className="flex gap-3 group rounded-lg border border-gray-700 p-5">
            <p className="text-white flex items-center text-lg font-medium">
              <LuAirplay className="text-green-400 mr-2" /> Yes (Use Panel)
            </p>
          </div>
          <div className="flex gap-3 group rounded-lg border border-gray-700 p-5">
            <p className="text-white flex items-center text-lg font-medium">
              <LuAppWindow className="text-green-400 mr-2" /> Yes (Use Termux)
            </p>
          </div>
          <div className="flex gap-3 group rounded-lg border border-gray-700 p-5">
            <p className="text-white flex items-center text-lg font-medium">
              <LuStar className="text-yellow-400 mr-2" />
              {product.rating} Ratings
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
