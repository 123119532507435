import React from 'react';
import { Navigate } from 'react-router-dom';

// Protected Route that checks if the user is authenticated
const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  
  if (!token) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  return children; // Render children (protected component)
};

export default PrivateRoute;
