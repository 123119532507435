import React from 'react';
import Star from './Star';

function Rating({ rating }) {
  const fullStars = Math.floor(rating); // Jumlah bintang penuh
  const hasHalfStar = rating % 1 !== 0; // Apakah ada setengah bintang
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Jumlah bintang kosong

  return (
    <div className="mt-8 flex items-center">
      <div className="flex text-yellow-400">
        {/* Render bintang penuh */}
        {Array.from({ length: fullStars }).map((_, i) => (
          <Star key={`full-${i}`} filled={true} />
        ))}
        {/* Render setengah bintang jika ada */}
        {hasHalfStar && <Star key="half" half={true} />}
        {/* Render bintang kosong */}
        {Array.from({ length: emptyStars }).map((_, i) => (
          <Star key={`empty-${i}`} filled={false} />
        ))}
      </div>
    </div>
  );
}

export default Rating;
