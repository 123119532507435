import React from 'react';

function ProductInfo({ product, handleDownload }) {
  return (
    <div className="flex items-center gap-4">
      <img
        src={product.foto}
        alt={product.nama}
        className="w-20 h-20 rounded-full transform transition-all hover:scale-105"
      />
      <div>
        <h1 className="text-3xl font-bold mt-2">{product.nama}</h1>
        <p className="text-gray-400">
          <strong>Developer: </strong>
          {product.developer}
        </p>
      </div>
    </div>
  );
}

export default ProductInfo;
