import React from 'react';

const Star = ({ filled, half }) => {
  if (half) {
    return (
      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" stroke="currentColor">
        <path d="M9.049 2.927a1 1 0 0 1 1.902 0l2.1 4.268 4.717.688a1 1 0 0 1 .554 1.707l-3.413 3.325.806 4.722a1 1 0 0 1-1.451 1.054l-4.242-2.227-4.243 2.227a1 1 0 0 1-1.451-1.054l.807-4.722-3.413-3.325a1 1 0 0 1 .554-1.707l4.718-.688 2.1-4.268z" />
      </svg>
    );
  }

  return (
    <svg className="w-6 h-6" fill={filled ? "currentColor" : "none"} stroke="currentColor" viewBox="0 0 20 20">
      <path d="M9.049 2.927a1 1 0 0 1 1.902 0l2.1 4.268 4.717.688a1 1 0 0 1 .554 1.707l-3.413 3.325.806 4.722a1 1 0 0 1-1.451 1.054l-4.242-2.227-4.243 2.227a1 1 0 0 1-1.451-1.054l.807-4.722-3.413-3.325a1 1 0 0 1 .554-1.707l4.718-.688 2.1-4.268z" />
    </svg>
  );
};

export default Star;
