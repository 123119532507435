import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './components/PrivateRoute'; // Import the PrivateRoute component
import ConfirmEmail from './pages/ConfirmEmail'; // Import ConfirmEmail component
import './styles.css';  // Import your CSS file here

function App() {
  return (
    <Router>
      <div className="App bg-dark text-light">
        <Routes> {/* Routes container */}
          <Route path="/" element={<ProductList />} />
          <Route path="/detail/:id" element={<ProductDetail />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />

          {/* Route for email confirmation */}
          <Route path="/auth/confirm/:token" element={<ConfirmEmail />} />

          {/* Protected route */}
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
