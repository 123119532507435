import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function ProductSlider({ photos, openModal }) {
  return (
    <div className="mt-8 m-2">
      <h3 className="text-lg font-medium text-white">screen script</h3>
      <Swiper spaceBetween={10} slidesPerView={3}>
        {photos.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="relative pb-[177.78%]">
              <img
                src={image}
                alt={`product-${index}`}
                className="absolute top-0 left-0 w-full h-full object-cover rounded-sm cursor-pointer transform transition-transform duration-500 hover:scale-110"
                onClick={() => openModal(image)}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ProductSlider;
