import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./queryClient";
import App from "./App";

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
      <App />
  </QueryClientProvider>,
  document.getElementById("root")
);
