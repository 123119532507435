import React from 'react';

function ImageModal({ isOpen, image, onClose }) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative max-w-4xl max-h-full">
        <img src={image} alt="Enlarged Product" className="w-full h-auto object-contain rounded-lg" />
        <button className="absolute top-4 right-4 text-white text-2xl" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
}

export default ImageModal;
