import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const ConfirmEmail = () => {
  const { token } = useParams();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/auth/confirm/${token}`);
        setMessage(response.data.message);
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
      } catch (error) {
        setError(error.response ? error.response.data.message : 'An error occurred');
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return (
    <div className="confirmation-container">
      <h2>Email Confirmation</h2>
      {message && <div className="success-message">{message}</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ConfirmEmail;
